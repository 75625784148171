import { Link, Typography } from '@mui/material';
import HTMLParse from './HTMLParse';

function CategoryTitle({ category, height = 25, ...props }) {
    if (!category) {
        return null;
    }

    return (
        <Link href={`/${category.slug}`} underline="hover">
            <Typography
                fontWeight="medium"
                color="#919191"
                textTransform="uppercase"
                fontSize={15}
                marginBottom={1}
                {...props}
            >
                <HTMLParse value={category.name} />
            </Typography>
        </Link>
    );
}

export default CategoryTitle;
