import React from 'react';
import { Box, Modal } from '@mui/material';

import ModalCard from './ModalCard';

function MailingListModal({ show, onClose }) {
    return (
        <Modal
            open={show}
            onClose={onClose}
            onBackdropClick={onClose}
            className="modal-center"
        >
            <ModalCard show={show} onClose={onClose} width={550}>
                <Box width={1} height={1}>
                    <iframe
                        title="mailing-list"
                        style={{ width: 550, height: 600, border: 0 }}
                        src="https://cdn.forms-content.sg-form.com/9a2eed59-4fc7-11ed-84b6-beb0819b9a3f"
                    />
                </Box>
            </ModalCard>
        </Modal>
    );
}

export default MailingListModal;
