import React from 'react';
import { Card, Fade, Box } from '@mui/material';
import CloseModal from './CloseModal';

function ModalCard(props, ref /*Do not remove*/) {
    const {
        show,
        onClose,
        children,
        width,
        height,
        type,
        padding = { xs: 4, md: 7 },
        padInside,
    } = props;

    return (
        <Fade in={show}>
            <Card
                variant="modal"
                type={type}
                sx={{
                    padding: padInside ? undefined : padding,
                }}
            >
                <Box
                    maxHeight={height}
                    sx={{
                        maxWidth: { xs: '100%', md: width },
                        width: { xs: 500, md: width },
                        padding: padInside ? padding : undefined,
                    }}
                >
                    <CloseModal onClose={onClose} />
                    {children}
                </Box>
            </Card>
        </Fade>
    );
}

export default React.forwardRef(ModalCard);
