import { useEffect, useState } from 'react';
import { Box, Dialog, Grid, IconButton, InputBase } from '@mui/material';
import axios from 'axios';
import PostCard from './PostCard';
import { Search } from '@mui/icons-material';
import NoData from './NoData';

function SearchBar() {
    const [showResults, setShowResults] = useState(false);
    const [search, setSearch] = useState('');
    const [results, setResults] = useState();

    useEffect(() => {
        const delay = setTimeout(() => {
            if (search?.length > 0) {
                handleSearch(search);
            }
        }, 500);

        return () => clearTimeout(delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    function handleSearch(input) {
        axios
            .get(`/wp/v2/search?search=${input}&type=post&subtype=post`)
            .then((res) => {
                setResults(res.data);
                setShowResults(true);
            })
            .catch(function () {});
    }

    function handleFocus(e) {
        setShowResults(true);
    }

    function handleClose() {
        setShowResults(false);
    }

    return (
        <Box>
            <Box backgroundColor="#555" borderRadius={10}>
                <IconButton
                    sx={{ color: 'white' }}
                    size="large"
                    onClick={handleFocus}
                >
                    <Search fontSize="inherit" />
                </IconButton>
            </Box>
            <Dialog open={showResults} onClose={handleClose}>
                <SearchInput
                    results={results}
                    search={search}
                    setSearch={setSearch}
                />
            </Dialog>
        </Box>
    );
}

function SearchInput({ results, search, setSearch }) {
    return (
        <Box>
            <Box
                display="flex"
                component="form"
                action="/all"
                position="fixed"
                top={0}
                left={0}
                height={70}
                margin="auto"
                width="100vw"
            >
                <Box
                    sx={{
                        input: { color: 'dark' },
                        margin: 'auto',
                        paddingLeft: 2.5,
                        paddingRight: 0,
                        backgroundColor: 'white',
                        position: 'relative',
                        borderRadius: 10,
                    }}
                >
                    <InputBase
                        name="search"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        sx={{
                            height: 45,
                            width: '70vw',
                            maxWidth: 450,
                        }}
                        autoFocus
                    />
                    <IconButton
                        sx={{ color: 'dark', fontSize: 30 }}
                        type="submit"
                    >
                        <Search fontSize="inherit" />
                    </IconButton>
                </Box>
            </Box>
            <Grid
                container
                maxWidth="100%"
                width={450}
                height="70vh"
                padding={{ xs: 2, md: 3 }}
                gap={4}
                borderRadius={2}
                overflow="auto"
                boxShadow="rgba(0, 0, 0, 0.2) 0px 0px 5px 1px"
            >
                <SearchResults results={results} search={search} />
            </Grid>
        </Box>
    );
}

function SearchResults({ results, search }) {
    if (!search || search.length === 0 || !results) {
        return null;
    }

    if (results.length === 0) {
        return (
            <Box padding={{ xs: 2, md: 15 }} display="flex" margin="auto">
                <NoData description="Sorry, we couldn't find any results." />
            </Box>
        );
    }

    return results.map((post) => (
        <Grid item xs={12} key={post.id}>
            <PostCard id={post.id} />
        </Grid>
    ));
}

export default SearchBar;
