import { Typography, Link, Box, Button } from '@mui/material';
import React, { useState } from 'react';

import upsideLogo from '../assets/UpsideLogo_Black.svg';
import MailingListModal from './MailingListModal';
import SocialButtons from './SocialButtons';

// Payment methods
import Amex from '../assets/payment/card-amex.svg';
import MasterCard from '../assets/payment/card-mastercard-alt.svg';
import Visa from '../assets/payment/card-visa-alt.svg';
import Discover from '../assets/payment/card-discover.png';
import JCB from '../assets/payment/card-jcb.svg';
import UnionPay from '../assets/payment/card-union.svg';
import Diners from '../assets/payment/card-diners.svg';

function Footer() {
    return (
        <footer>
            <Box
                sx={{
                    display: 'flex',
                    backgroundColor: 'white',
                    boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.1)',
                }}
                padding={{ xs: 5, md: 5, lg: 10 }}
            >
                <LeftPanel />
                <RightPanel />
            </Box>
        </footer>
    );
}

const Section = ({ title, children }) => {
    return (
        <Box paddingRight={{ md: 5, lg: 12 }}>
            <Typography
                padding={1}
                textAlign="left"
                fontWeight="bold"
                fontSize={18}
                fontFamily="urbanist"
            >
                {title}
            </Typography>
            {children}
        </Box>
    );
};

const Item = ({ title, path }) => {
    return (
        <Typography
            padding={1}
            textAlign="left"
            fontSize={15}
            color="dark"
            letterSpacing={1}
            fontFamily="urbanist"
        >
            <Link
                href={`${process.env.REACT_APP_CLIENT}${path}`}
                underline="hover"
                color="dark"
            >
                {title}
            </Link>
        </Typography>
    );
};

const BannerItem = ({ title, path }) => {
    return (
        <Typography
            color="dark"
            letterSpacing={1}
            marginRight={3}
            component={path && Link}
            href={`${process.env.REACT_APP_CLIENT}${path}`}
            underline="hover"
        >
            {title}
        </Typography>
    );
};

const Logo = () => {
    return (
        <Link href="/">
            <Box
                component="img"
                sx={{
                    width: 200,
                    marginBottom: 3,
                }}
                src={upsideLogo}
            />
        </Link>
    );
};

const LeftPanel = () => {
    const [showMailingModal, setShowMailingModal] = useState(false);

    return (
        <Box
            sx={{
                padding: { md: '20px' },
            }}
            margin="auto"
        >
            <Box maxWidth={320}>
                <Logo />
                <ul style={{ padding: 0 }}>
                    <SocialButtons />
                </ul>
                <div>
                    <Typography
                        marginTop={5}
                        textAlign="left"
                        fontWeight="bold"
                        fontSize={20}
                        fontFamily="urbanist"
                        marginBottom={1}
                    >
                        Stay In The Loop
                    </Typography>
                    <Typography
                        textAlign="left"
                        fontSize={16}
                        marginBottom={2}
                        fontFamily="urbanist"
                    >
                        Join our mailing list to be the first to receive the
                        latest news, offerings & events.
                    </Typography>
                </div>
                <Button
                    variant="dark"
                    onClick={() => setShowMailingModal(true)}
                    fullWidth
                >
                    Join
                </Button>
                <MailingListModal
                    show={showMailingModal}
                    onClose={() => setShowMailingModal(false)}
                />
            </Box>
        </Box>
    );
};

const RightPanel = () => {
    const path = `/category`;
    const categories = [
        { slug: 'condo', plural: 'Condominiums' },
        { slug: 'detached', plural: 'Detached Homes' },
        { slug: 'semi-detached', plural: 'Semi-Detached Homes' },
        { slug: 'townhome', plural: 'Townhomes' },
    ];

    return (
        <Box margin="auto" display={{ xs: 'none', md: 'initial' }}>
            <Box display="flex" flexWrap="wrap">
                <Section title="Explore Properties">
                    <Item title="All Property Types" path={path} />
                    {categories.map((category) => (
                        <Item
                            title={category.plural}
                            path={`/category/${category.slug}`}
                            key={category.slug}
                        />
                    ))}
                </Section>

                <Section title="Company">
                    <Item title="About" path="/about" />
                    <Item title="Careers" path="/careers" />
                    <Item title="FAQ" path="/faq" />
                </Section>
            </Box>

            <PaymentIcons
                icons={[
                    MasterCard,
                    Amex,
                    Discover,
                    Diners,
                    JCB,
                    Visa,
                    UnionPay,
                ]}
            />

            <Box display="flex" flexWrap="wrap" paddingTop={3}>
                <BannerItem title="© FORT Labs Inc." />
                <BannerItem title="Privacy Policy" path="/privacy-policy" />
                <BannerItem
                    title="Terms & Conditions"
                    path="/terms-conditions"
                />
                <BannerItem title="Contact Us" path="/contact-us" />
                <BannerItem title="About Us" path="/about" />
            </Box>
        </Box>
    );
};

const PaymentIcons = ({ icons = [] }) => {
    return (
        <Box display="flex" flexWrap="wrap" marginTop={6}>
            {icons.map((icon) => (
                <img
                    key={icon}
                    height={40}
                    src={icon}
                    alt=""
                    style={{
                        objectFit: 'contain',
                        marginRight: 30,
                        marginBottom: 20,
                    }}
                />
            ))}
        </Box>
    );
};

export default Footer;
