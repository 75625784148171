import { Box, Tooltip } from '@mui/material';
import { useState } from 'react';
import {
    //EmailShareButton,
    FacebookShareButton,
    //InstapaperShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,
} from 'react-share';

import CopyIcon from '../assets/social/Icon_Copy.svg';
import FacebookIcon from '../assets/social/Icon_Facebook.svg';
import LinkedinIcon from '../assets/social/Icon_Linkedin.svg';
import RedditIcon from '../assets/social/Icon_Reddit.svg';
import TwitterIcon from '../assets/social/Icon_Twitter.svg';

function ShareButtons({ href, title }) {
    const socials = [
        { Icon: FacebookIcon, Element: FacebookShareButton, quote: title },
        { Icon: TwitterIcon, Element: TwitterShareButton, title: title },
        { Icon: LinkedinIcon, Element: LinkedinShareButton, title: title },
        { Icon: RedditIcon, Element: RedditShareButton, title: title },
        { Icon: CopyIcon, Element: CopyButton },
    ];

    return (
        <>
            {socials.map((social, i) => (
                <Item
                    key={i}
                    Icon={social.Icon}
                    Element={social.Element}
                    href={href}
                    spacing={1.5}
                    {...social}
                />
            ))}
        </>
    );
}

const Item = ({ Icon, Element, href, spacing, ...props }) => {
    return (
        <Box paddingRight={spacing}>
            <Element url={href} {...props}>
                <img src={Icon} width={22} height={22} />
            </Element>
        </Box>
    );
};

const CopyButton = ({ children }) => {
    const [clicked, setClicked] = useState(false);
    const [hovering, setHovering] = useState(false);

    function handleClick() {
        navigator.clipboard.writeText(window.location.href);
        setClicked(true);
    }

    function handleExit() {
        const delay = setTimeout(() => {
            setClicked(false);
        }, 100);

        return () => clearTimeout(delay);
    }

    return (
        <Tooltip title={clicked ? 'Copied to Clipboard' : ''}>
            <Box
                sx={{ cursor: 'pointer' }}
                onClick={handleClick}
                onMouseLeave={handleExit}
            >
                {children}
            </Box>
        </Tooltip>
    );
};

export default ShareButtons;
