import { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import NotFound from './NotFound';
import Loading from '../components/Loading';
import PaddedBox from '../components/PaddedBox';
import Footer from '../components/Footer';
import SecondaryNav from '../components/SecondaryNav';
import PostList from '../components/PostList';
import PostCard from '../components/PostCard';
import Pagination from '../components/Pagination';

function Category() {
    const { category } = useParams();
    const [data, setData] = useState();
    const [posts, setPosts] = useState();
    const [failed, setFailed] = useState(false);

    // Search params
    const [searchParams] = useSearchParams();

    // Pagination
    const [pageCount, setPageCount] = useState();
    const [page, setPage] = useState(+searchParams.get('page') || 1);

    useEffect(() => {
        // Get category data
        axios
            .get(`/wp/v2/categories?slug=${category}`)
            .then((res) => {
                const results = res.data[0];

                if (results) {
                    setData(results);
                } else {
                    setFailed(true);
                }
            })
            .catch(function () {
                setFailed(true);
            });
    }, [category]);

    useEffect(() => {
        // Get blog posts for category
        if (data) {
            axios
                .get(
                    `/wp/v2/posts?categories=${
                        data.id
                    }&page=${page}&per_page=${9}`
                )
                .then((res) => {
                    setPosts(res.data);
                    setPageCount(+res.headers['x-wp-totalpages']);
                })
                .catch(function () {
                    setFailed(true);
                });
        }
    }, [data, page]);

    // Exit if data not found
    if (failed) {
        return (
            <>
                <SecondaryNav />
                <NotFound heightOffset={420} />
                <Footer />
            </>
        );
    }
    // Indicate page is loading
    if (!data || !posts) {
        return (
            <>
                <SecondaryNav />
                <Loading height={400} />
                <Footer />
            </>
        );
    }

    return (
        <Box>
            <SecondaryNav />
            <Box paddingTop={6} marginBottom={8}>
                <PaddedBox>
                    <Posts posts={posts} page={page} />
                    <Pagination
                        page={+page}
                        setPage={setPage}
                        max={+pageCount}
                        posts={posts}
                        setPosts={setPosts}
                    />
                </PaddedBox>
            </Box>
            <Footer />
        </Box>
    );
}

// Organized this component because of how messy it would be otherwise
function Posts({ posts = [], page, widePosts = 0 }) {
    const components = [];

    // Conditional rendering factors
    const count = posts.length || 0;
    const hasFeatured = page === 1 && count > 0 && widePosts > 0;
    const hasExtra = count === 0 || count > (hasFeatured ? widePosts : 0);
    const requiresDivider = hasFeatured && hasExtra;

    // Featured blog post at top of category page
    if (hasFeatured) {
        components.push(
            <Grid container gap={5}>
                {posts.slice(0, 3).map((post, i) => (
                    <PostCard key={i} slug={post?.slug} wide />
                ))}
            </Grid>
        );
    }

    // Divider
    if (requiresDivider) {
        components.push(<hr style={{ marginTop: 70, marginBottom: 70 }} />);
    }

    // List of blog posts cards
    if (hasExtra) {
        components.push(
            <PostList posts={posts.slice(page === 1 ? widePosts : 0)} />
        );
    }

    return components.map((component, i) => <div key={i}>{component}</div>);
}

export default Category;
