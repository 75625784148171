import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import PostCard from './PostCard';
import PostList from './PostList';
import { Circle } from '@mui/icons-material';
import axios from 'axios';

function PostCardCycle({ posts = [], rate = 15000 }) {
    const [postData, setPostData] = useState([]);
    const [index, setIndex] = useState(0);
    const [timer, setTimer] = useState();

    useEffect(() => {
        // Retrieve the data once so that it doesn't get re-queried every time the cards cycle
        if (posts?.length > 0) {
            let promises = [];

            for (let i = 0; i < posts.length; i++) {
                promises.push(
                    axios.get(`/wp/v2/posts?slug=${posts[i].slug}&_embed=1`)
                );
            }

            Promise.all(promises).then((results) => {
                let data = [];

                for (let i = 0; i < results.length; i++) {
                    data.push(results[i].data[0]);
                }

                setPostData(data);
            });
        }
    }, [posts]);

    useEffect(() => {
        if (posts?.length > 0) {
            resetTimer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, posts]);

    function handleTick() {
        let i = index + 1;
        if (i > posts?.length - 1) {
            i = 0;
        }
        setIndex(i);
    }

    function handleChange(i) {
        setIndex(i);
        resetTimer();
    }

    function resetTimer() {
        clearInterval(timer);
        setTimer(setInterval(handleTick, rate));
    }

    return (
        <Box>
            <Box>
                <PostCard postData={postData?.[index]} wide />
            </Box>
            <Box
                marginBottom={4}
                marginTop={4}
                display="flex"
                justifyContent="space-around"
                zIndex={1}
                position="relative"
            >
                <Dots index={index} handleChange={handleChange} posts={posts} />
            </Box>
            <PostList postData={reorderList(index, postData)?.slice(1)} />
        </Box>
    );
}

function Dots({ index, handleChange, posts }) {
    return (
        <Box>
            {posts?.map((post, i) => (
                <Circle
                    key={post.id}
                    onClick={() => handleChange(i)}
                    sx={{
                        color: i === index ? 'dark' : '#f1f1f1',
                        backgroundColor: i === index ? 'dark' : '#f1f1f1',
                        border: '1px #212529 solid',
                        cursor: 'pointer',
                        marginLeft: 1,
                        marginRight: 1,
                        width: '15px',
                        height: '15px',
                        borderRadius: '200px',
                    }}
                />
            ))}
        </Box>
    );
}

function reorderList(index, posts) {
    let list = [...posts];

    for (let i = 0; i < index; i++) {
        list.push(list.shift());
    }

    return list;
}

export default PostCardCycle;
