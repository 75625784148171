import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import PaddedBox from './PaddedBox';
import PostList from './PostList';

function RelatedPosts({ categories, title, existingId }) {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios
            .post(`/api/random`, { categories: categories, id: existingId })
            .then((res) => {
                setPosts(res.data);
            })
            .catch(function () {});
    }, [categories, existingId]);

    return (
        <Box
            backgroundColor="tint"
            paddingTop={{ xs: 9, md: 12 }}
            paddingBottom={{ xs: 9, md: 12 }}
        >
            <PaddedBox>
                <Typography
                    fontSize={20}
                    color="#919191"
                    textTransform="uppercase"
                    marginBottom={5}
                    textAlign="center"
                    letterSpacing={2}
                >
                    {`More Related Articles`}
                </Typography>
                <PostList posts={posts} attribute="post_name" />
            </PaddedBox>
        </Box>
    );
}

export default RelatedPosts;
