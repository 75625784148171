import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import muiTheme from './muiTheme';
import Header from './components/Header';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Post from './pages/Post';
import Category from './pages/Category';
import All from './pages/All';

const theme = createTheme(muiTheme);

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Header />
                <Pages />
            </Router>
        </ThemeProvider>
    );
}

const Pages = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />

            <Route exact path="/post/:slug" element={<Post />} />

            <Route exact path="/:category" element={<Category />} />

            <Route exact path="/all" element={<All />} />

            <Route exact path="*" element={<NotFound />} />
        </Routes>
    );
};

export default App;
