import { Link, Typography } from '@mui/material';
import ArrowOutward from '@mui/icons-material/ArrowOutward';

function ReadPost({ href }) {
    return (
        <Link
            href={href}
            underline="hover"
            display="flex"
            color="dark"
            fontSize={24}
            component="div"
            className="read-post"
        >
            <Typography fontWeight="bold" marginRight={0.5}>
                Read post
            </Typography>
            <ArrowOutward fontSize="inherit" />
        </Link>
    );
}

export default ReadPost;
