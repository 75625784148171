import { useEffect, useState } from 'react';
import { AppBar, Box, Fade, Link, Toolbar, Typography } from '@mui/material';
import PaddedBox from './PaddedBox';
import ScrollContainer from 'react-indiana-drag-scroll';

function handleScroll(setFade) {
    setFade(getScrollInfo());
}

function getScrollInfo() {
    const el = document.getElementsByClassName('secondary-nav-scroll')[0];

    if (!el) {
        return {};
    }

    const scroll = el.scrollLeft;
    const max = el.scrollWidth - el.clientWidth;
    const buffer = 15;

    return {
        left: scroll > buffer,
        right: scroll < max - buffer,
    };
}

function NavigationFade({ inCondition, direction, left, right }) {
    return (
        <Fade in={inCondition} style={{ pointerEvents: 'none' }}>
            <Box
                position="absolute"
                left={left}
                right={right}
                height="100%"
                width="15vw"
                maxWidth={100}
                sx={{
                    backgroundImage: `linear-gradient(to ${direction}, white, transparent)`,
                }}
            />
        </Fade>
    );
}

function SecondaryNav() {
    const [fade, setFade] = useState({});

    useEffect(() => {
        // Setup scroll fades
        handleScroll(setFade);

        function handleResize() {
            handleScroll(setFade);
        }

        // Handle scroll fades whenever window resizes
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Box>
            <Typography
                variant="h2"
                marginTop={25}
                marginBottom={10}
                textAlign="center"
                fontFamily="superior-title"
            >
                Upside Blog
            </Typography>
            <AppBar elevation={0} position="static">
                <PaddedBox backgroundColor="white">
                    <Toolbar style={{ padding: 0, position: 'relative' }}>
                        <NavigationFade
                            inCondition={fade.left}
                            left={0}
                            direction="right"
                        />
                        <NavigationFade
                            inCondition={fade.right}
                            right={0}
                            direction="left"
                        />
                        <ScrollContainer
                            style={{
                                flexGrow: 1,
                                display: 'flex',
                            }}
                            onScroll={() => {
                                handleScroll(setFade);
                            }}
                            className="secondary-nav-scroll"
                        >
                            <NavItem title="New & Featured" path="/" />
                            <NavItem
                                title="Featured Upcoming Listings"
                                path="/upcoming"
                            />
                            <NavItem title="Analysis & Trends" path="/trends" />
                            <NavItem title="Market Insight" path="/insights" />
                            <NavItem title="Investing 101" path="/investing" />
                            <NavItem title="Upside Platform" path="/upside" />
                            <NavItem title="All" path="/all" />
                        </ScrollContainer>
                        {/* <Box
                            backgroundColor="white"
                            margin="0 auto"
                            display="flex"
                            width="100%"
                        >
                        </Box> */}
                    </Toolbar>
                </PaddedBox>
            </AppBar>
        </Box>
    );
}

const NavItem = ({ title, path, ...props }) => {
    // Check if current nav item path is the user's url path
    const match = path === window.location.pathname;

    return (
        <Link
            href={path}
            underline="none"
            borderBottom={match ? '2px solid #212529' : '2px solid #ccc'}
            paddingBottom={1.5}
            sx={{
                '&:hover': {
                    borderBottom: '2px solid #666',
                },
            }}
            flex={title.length}
            minWidth={title.length * 9}
            {...props}
        >
            <Typography
                color="dark"
                fontWeight={match ? 'bold' : 'medium'}
                textAlign="center"
            >
                {title}
            </Typography>
        </Link>
    );
};

export default SecondaryNav;
