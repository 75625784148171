import { Box, TextField } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function Filter({
    setPosts = () => {},
    setPageCount = () => {},
    setFailed = () => {},
    page,
    setPage = () => {},
    perPage = 9,
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState(searchParams.get('search') || '');

    useEffect(() => {
        handleSearch(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        const delay = setTimeout(() => {
            // Reset url params before doing the search
            setPage(1);
            let params = new URLSearchParams();
            params.delete('page');
            setSearchParams(params);

            handleSearch(1);
        }, 500);

        return () => clearTimeout(delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    function handleSearch(page) {
        axios
            .get(
                `/wp/v2/posts?&per_page=${perPage}&page=${page}&search=${search}`
            )
            .then((res) => {
                setPosts(res.data);
                setPageCount(+res.headers['x-wp-totalpages']);
            })
            .catch(function () {
                setFailed(true);
            });
    }

    return (
        <Box flex={1}>
            <TextField
                name="search"
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value);
                }}
                sx={{
                    input: { color: 'dark' },
                }}
                fullWidth
                placeholder="Search"
            />
        </Box>
    );
}

export default Filter;
