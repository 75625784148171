import { useState, useEffect } from 'react';
import { Box, Typography, CardMedia, Link } from '@mui/material';
import { KeyboardBackspace } from '@mui/icons-material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import NotFound from './NotFound';
import Loading from '../components/Loading';
import PaddedBox from '../components/PaddedBox';
import HTMLParse from '../components/HTMLParse';
import moment from 'moment';
import Footer from '../components/Footer';
import RelatedPosts from '../components/RelatedPosts';
import ShareButtons from '../components/ShareButtons';
import CategoryTitle from '../components/CategoryTitle';
import { updateEmbedded } from '../scripts/wordpress';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';

function Post() {
    const { slug } = useParams();
    const [data, setData] = useState();
    const [failed, setFailed] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // Get blog post data
        axios
            .get(`/wp/v2/posts?slug=${slug}&_embed=1`)
            .then((res) => {
                const result = res.data[0];

                if (result) {
                    updateEmbedded(result._embedded, setCategories);
                    setData(result);
                } else {
                    setFailed(true);
                }
            })
            .catch(function () {
                setFailed(true);
            });
    }, [slug]);

    // Exit if data not found
    if (failed) {
        return <NotFound />;
    }
    // Indicate page is loading
    if (!data) {
        return <Loading />;
    }

    return (
        <Box backgroundColor="white" paddingTop={{ xs: 5, md: 12 }}>
            <Helmet>{parse(data.yoast_head || '')}</Helmet>
            <PaddedBox
                left={{ xs: 3, md: '20vw', lg: '30vw' }}
                right={{ xs: 3, md: '20vw', lg: '30vw' }}
            >
                <Link
                    color="dark"
                    display="flex"
                    width={66}
                    underline="none"
                    href={`/${categories[0].slug}`}
                >
                    <KeyboardBackspace />
                    <Typography fontWeight="bold" marginLeft={0.5}>
                        Back
                    </Typography>
                </Link>
                <Box display="flex" justifyContent="center">
                    <CategoryTitle
                        category={categories[0]}
                        fontSize={18}
                        height={35}
                        letterSpacing={2}
                        marginTop={{ xs: 3, md: 1 }}
                    />
                </Box>
                <Typography
                    variant="h3"
                    marginTop={2.5}
                    marginBottom={2.5}
                    fontFamily="superior-title"
                    textAlign="center"
                >
                    <HTMLParse value={data.title.rendered} />
                </Typography>

                <Typography textAlign="center" width="100%" marginBottom={3}>
                    {moment(data.date).format(`D MMMM, YYYY`)} - Published by
                    Upside
                </Typography>

                <Box display="flex" marginBottom={6} justifyContent="center">
                    <ShareButtons
                        href={window.location.href}
                        title={parse(data.title.rendered || '')}
                    />
                </Box>

                <CardMedia
                    sx={{
                        display: 'block',
                        width: '100%',
                        height: '100%',
                        marginBottom: 6,
                    }}
                    src={data.acf?.banner}
                    component="img"
                />

                <Typography
                    fontSize={18}
                    component="div"
                    letterSpacing={0.25}
                    lineHeight={1.6}
                    marginBottom={15}
                >
                    <HTMLParse value={data.content.rendered} />
                </Typography>
            </PaddedBox>

            <RelatedPosts categories={data.categories} existingId={data.id} />
            <Footer />
        </Box>
    );
}

export default Post;
