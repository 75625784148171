import React from 'react';
import * as Icons from '@mui/icons-material';

function DynamicIcon({ icon, DefaultIcon = 'AddCircle', colour, size, sx }) {
    var Icon = Icons[icon] || Icons[DefaultIcon];

    return <Icon sx={{ color: colour, width: size, height: size, ...sx }} />;
}

export default DynamicIcon;
