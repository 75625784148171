import { Grid, Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

function Pagination({ page, setPage, max, posts, setPosts }) {
    const [, setSearchParams] = useSearchParams();

    function handleClick(value) {
        // Check if page is valid, and not the same as current
        if (value > 0 && value <= max && page !== value) {
            // Update page to query for
            setPage(value);
            // Scroll to top
            window.scrollTo({ top: 270, behavior: 'smooth' });
            // Empty array so it looks like the data is loading
            setPosts();

            // Update url params
            let params = new URLSearchParams();
            params.set('page', value);
            setSearchParams(params);
        }
    }

    // List of page numbers
    const numberList = [];
    for (let i = 0; i < max; i++) {
        // Limit button count to max 6 at a time -> [1 2 3 4 5 11]
        if (i >= 5) {
            // Add current page to page list if not in the visible range (1-5 ... max)
            if (page > i && page !== max) {
                // Limit button count to 6 -> [1 2 3 4 7 11]
                numberList.pop();
                // Add current page to list
                numberList.push(
                    <PageButton value={page} label={page} key={i} />
                );
            }

            // Add final page to list
            numberList.push(<PageButton value={max} label={max} key={max} />);

            // Don't add any more buttons
            break;
        }

        // Standard button (1-5)
        numberList.push(<PageButton value={i + 1} label={i + 1} key={i} />);
    }

    function PageButton({ value, label, disabled }) {
        return (
            <Button
                variant={value === page ? 'dark' : 'white'}
                onClick={() => handleClick(value)}
                sx={{ padding: '20px 0', height: 65, width: 0 }}
                disabled={disabled}
            >
                {label}
            </Button>
        );
    }

    // Hide page selector if there's no data
    if (posts?.length <= 0 || !posts) {
        return null;
    }

    return (
        <Grid
            container
            gap={1}
            padding={2}
            display="flex"
            justifyContent="center"
            marginTop={10}
        >
            <PageButton
                value={page - 1}
                label={<ArrowBackIos fontSize="xsmall" />}
                disabled={page === 1}
            />
            {numberList.map((button) => button)}
            <PageButton
                value={page + 1}
                label={<ArrowForwardIos fontSize="xsmall" />}
                disabled={page === max}
            />
        </Grid>
    );
}

export default Pagination;
