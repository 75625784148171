import { Box, Grid, IconButton } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DynamicIcon from '../components/DynamicIcon';
import Filter from '../components/Filter';
import Footer from '../components/Footer';
import PaddedBox from '../components/PaddedBox';
import Pagination from '../components/Pagination';
import PostList from '../components/PostList';
import SecondaryNav from '../components/SecondaryNav';
import NotFound from './NotFound';

function All() {
    const [posts, setPosts] = useState();
    const [failed, setFailed] = useState(false);
    const [wide, setWide] = useState(
        JSON.parse(localStorage.getItem('wide')) === true
    );

    // Search params
    const [searchParams] = useSearchParams();

    // Pagination
    const [pageCount, setPageCount] = useState();
    const [page, setPage] = useState(+searchParams.get('page') || 1);

    // Exit if data not found
    if (failed) {
        return (
            <>
                <SecondaryNav />
                <NotFound heightOffset={420} />
                <Footer />
            </>
        );
    }

    return (
        <Box>
            <SecondaryNav />
            <Box paddingTop={5.5} marginBottom={13}>
                <PaddedBox position="relative">
                    <Grid marginBottom={6} display="flex" gap={1}>
                        <Filter
                            setPosts={setPosts}
                            setPageCount={setPageCount}
                            setFailed={setFailed}
                            page={page}
                            setPage={setPage}
                        />
                        <CardSizeSelector wide={wide} setWide={setWide} />
                    </Grid>
                    <PostList posts={posts} wide={wide} indicateLoad />
                    <Pagination
                        page={+page}
                        setPage={setPage}
                        max={+pageCount}
                        posts={posts}
                        setPosts={setPosts}
                    />
                </PaddedBox>
            </Box>
            <Footer />
        </Box>
    );
}

function CardSizeSelector({ wide, setWide, size = 56 }) {
    function handleChange(wide) {
        setWide(wide);
        localStorage.setItem('wide', wide);
    }

    return (
        <>
            <IconButton
                sx={{ width: size }}
                onClick={() => handleChange(false)}
            >
                <DynamicIcon
                    icon={wide ? 'WindowOutlined' : 'Window'}
                    //colour={wide ? 'inherit' : 'status.info'}
                />
            </IconButton>
            <IconButton sx={{ width: size }} onClick={() => handleChange(true)}>
                <DynamicIcon
                    icon={wide ? 'ViewAgenda' : 'ViewAgendaOutlined'}
                    //colour={wide ? 'status.info' : 'inherit'}
                />
            </IconButton>
        </>
    );
}

export default All;
