import { Box, Typography } from '@mui/material';

function NotFound({ heightOffset = 70 }) {
    return (
        <Box display="flex" height={`calc(100vh - ${heightOffset}px)`}>
            <Typography textAlign="center" margin="auto" display="flex">
                404 not found
            </Typography>
        </Box>
    );
}

export default NotFound;
