import { useState, useEffect } from 'react';
import {
    Box,
    CardMedia,
    Grid,
    Link,
    Skeleton,
    Typography,
} from '@mui/material';
import axios from 'axios';
import HTMLParse from './HTMLParse';
import ReadPost from './ReadPost';
import CategoryTitle from './CategoryTitle';
import { updateEmbedded } from '../scripts/wordpress';
import config from '../config';
import FeaturedIcon from '../assets/Upside_Featured_Icon.svg';

function PostCard({ id, slug, postData, wide }) {
    const [data, setData] = useState();
    const [categories, setCategories] = useState([]);
    const [featured, setFeatured] = useState(false);

    // Get blog post data
    useEffect(() => {
        if (id) {
            axios
                .get(`/wp/v2/posts/${id}?_embed=1`)
                .then((res) => {
                    handleQuery(res.data);
                })
                .catch(function () {});
        } else if (slug) {
            axios
                .get(`/wp/v2/posts?slug=${slug}&_embed=1`)
                .then((res) => {
                    handleQuery(res.data[0]);
                })
                .catch(function () {});
        }
    }, [id, slug]);

    useEffect(() => {
        if (postData) {
            setData(postData);
            handleQuery(postData);
        }
    }, [postData]);

    function handleQuery(result) {
        setData(result);
        updateEmbedded(result._embedded, setCategories);
        setFeatured(result.tags.includes(config.featuredTagId));
    }

    // Variants
    if (wide) {
        return <Wide data={data} categories={categories} featured={featured} />;
    }

    return <Standard data={data} categories={categories} featured={featured} />;
}

function Standard({ data, categories, featured }) {
    if (!data) {
        return (
            <Box>
                <Skeleton height={180} variant="rounded" />
                <Skeleton
                    height={30}
                    width="50%"
                    sx={{ marginTop: 1, marginBottom: 1 }}
                />
                <Skeleton height={25} width="40%" sx={{ marginBottom: 0.5 }} />
                <Skeleton height={30} width={100} />
            </Box>
        );
    }

    return (
        <Box position="relative" className="post-card">
            <Link
                href={`/post/${data.slug}`}
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 10,
                }}
            />
            <CardMedia
                sx={{
                    width: '100%',
                    height: 180,
                    objectFit: 'cover',
                    backgroundColor: '#eee',
                    borderRadius: 2,
                }}
                image={data.acf?.banner}
            />
            {featured && (
                <img
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 15,
                        objectFit: 'contain',
                    }}
                    height={46}
                    src={FeaturedIcon}
                />
            )}
            <Typography
                color="dark"
                className="clamp2 parser"
                marginTop={2}
                fontFamily="superior-title"
                lineHeight={1.25}
                marginBottom={1.5}
                fontSize={18}
            >
                <HTMLParse value={data.title.rendered} />
            </Typography>
            <CategoryTitle category={categories[0]} letterSpacing={1.5} />
            <ReadPost href={`/post/${data.slug}`} />
        </Box>
    );
}

function Wide({ data, categories, featured }) {
    if (!data) {
        return (
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={8}
                    paddingRight={{ xs: 0, md: 8 }}
                    paddingBottom={{ xs: 2, md: 0 }}
                >
                    <Skeleton
                        sx={{ height: { xs: 200, md: 400 } }}
                        variant="rounded"
                    />
                </Grid>
                <Grid item xs={12} md={4} display="flex">
                    <Box
                        marginLeft={{ xs: 0, md: 2 }}
                        marginTop="auto"
                        marginBottom="auto"
                        width="100%"
                    >
                        <Skeleton
                            height={40}
                            width="80%"
                            sx={{ marginBottom: 1 }}
                        />
                        <Skeleton
                            height={25}
                            width="30%"
                            sx={{ marginBottom: 1 }}
                        />
                        <Skeleton height={24} width="100%" />
                        <Skeleton height={24} width="93%" />
                        <Skeleton height={24} width="98%" />
                        <Skeleton height={24} width="95%" />
                        <Skeleton height={24} width="97%" />
                        <Skeleton
                            height={30}
                            width={100}
                            sx={{ marginTop: 1 }}
                        />
                    </Box>
                </Grid>
            </Grid>
        );
    }

    return (
        <Box position="relative" display="flex" className="post-card">
            <Link
                href={`/post/${data.slug}`}
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 10,
                }}
            />
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={8}
                    paddingRight={{ xs: 0, md: 8 }}
                    paddingBottom={{ xs: 2, md: 0 }}
                >
                    <CardMedia
                        sx={{
                            width: '100%',
                            height: { xs: 200, md: 400 },
                            objectFit: 'cover',
                            backgroundColor: '#eee',
                            borderRadius: 2,
                            position: 'relative',
                        }}
                        image={data.acf?.banner}
                    >
                        {featured && (
                            <img
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 15,
                                    objectFit: 'contain',
                                }}
                                height={46}
                                src={FeaturedIcon}
                            />
                        )}
                    </CardMedia>
                </Grid>
                <Grid item xs={12} md={4} display="flex">
                    <Box
                        marginLeft={{ xs: 0, md: 2 }}
                        marginTop="auto"
                        marginBottom="auto"
                    >
                        <Typography
                            color="dark"
                            className="clamp3"
                            marginTop={1}
                            variant="h5"
                            gutterBottom
                            fontFamily="superior-title"
                        >
                            <HTMLParse value={data.title.rendered} />
                        </Typography>
                        <CategoryTitle
                            category={categories[0]}
                            letterSpacing={1.5}
                        />
                        <Typography
                            color="dark"
                            className="clamp5 parser"
                            fontWeight="medium"
                            component="div"
                            marginBottom={1.5}
                            marginTop={1.5}
                        >
                            <HTMLParse value={data.excerpt.rendered} />
                        </Typography>
                        <ReadPost href={`/post/${data.slug}`} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default PostCard;
