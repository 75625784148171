import { Grid } from '@mui/material';
import Loading from './Loading';
import NoData from './NoData';
import PostCard from './PostCard';

function PostList({
    posts,
    postData,
    attribute = 'slug',
    wide,
    spacing = { xs: 5, md: 8 },
    rowSpacing,
    columnSpacing,
    children,
    indicateLoad = false,
    ...props
}) {
    // Content loading
    if (!posts && !postData) {
        return indicateLoad ? <Loading height={200} /> : null;
    }
    // List empty, display empty indication
    else if (posts?.length === 0 || !postData?.length === 0) {
        return children ? children : <NoData />;
    }

    return (
        <Grid
            container
            spacing={spacing}
            rowSpacing={rowSpacing}
            columnSpacing={columnSpacing}
        >
            {posts?.map((post, i) => (
                <Grid item xs={12} md={wide ? 12 : 4} key={i}>
                    <PostCard slug={post[attribute]} wide={wide} {...props} />
                </Grid>
            ))}
            {postData?.map((post, i) => (
                <Grid item xs={12} md={wide ? 12 : 4} key={i}>
                    <PostCard postData={post} wide={wide} {...props} />
                </Grid>
            ))}
        </Grid>
    );
}
export default PostList;
