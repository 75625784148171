import upsideLogo from '../assets/UpsideLogo_White.svg';
import upsideLogoSmall from '../assets/UpsideLogo_White_Small.svg';
import {
    AppBar,
    Box,
    Button,
    Link,
    Toolbar,
    useMediaQuery,
} from '@mui/material';
import PaddedBox from './PaddedBox';
import SearchBar from './SearchBar';

function Header() {
    const xs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <Box sx={{ flexGrow: 1, marginBottom: '70px' }}>
            <AppBar variant="dark" elevation={0} position="fixed">
                <PaddedBox>
                    <Toolbar style={{ height: '70px', padding: 0 }}>
                        <Link href={process.env.REACT_APP_TYPEDREAM}>
                            <Box
                                component="img"
                                sx={{
                                    height: 45,
                                    paddingLeft: 1,
                                    paddingRight: 1,
                                }}
                                src={xs ? upsideLogoSmall : upsideLogo}
                            />
                        </Link>

                        <Box
                            flex={1}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                position: { xs: 'inherit', md: 'absolute' },
                                top: { md: 9 },
                                left: { md: 'calc(50% - 25px)' },
                            }}
                        >
                            <SearchBar />
                        </Box>

                        <Box display="flex" marginLeft="auto">
                            <Button
                                variant="white"
                                size="tiny"
                                sx={{
                                    marginRight: 1,
                                    paddingLeft: { xs: 2, md: undefined },
                                    paddingRight: { xs: 2, md: undefined },
                                }}
                                component={Link}
                                href={`${process.env.REACT_APP_CLIENT}/auth?show=signin`}
                                target="_blank"
                            >
                                Upside App
                            </Button>
                            <Button
                                variant="blue"
                                size="tiny"
                                sx={{
                                    paddingLeft: { xs: 2, md: undefined },
                                    paddingRight: { xs: 2, md: undefined },
                                }}
                                component={Link}
                                href={`${process.env.REACT_APP_TYPEDREAM}/waitlist`}
                            >
                                Join Waitlist
                            </Button>
                        </Box>
                    </Toolbar>
                </PaddedBox>
            </AppBar>
        </Box>
    );
}

export default Header;
