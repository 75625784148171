import { useState, useEffect } from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import Footer from '../components/Footer';
import SecondaryNav from '../components/SecondaryNav';
import PaddedBox from '../components/PaddedBox';
import axios from 'axios';
import PostList from '../components/PostList';
import config from '../config';
import PostCardCycle from '../components/PostCardCycle';

function Home() {
    const [latestPosts, setLatestPosts] = useState();
    const [featuredPosts, setFeaturedPosts] = useState();
    const [whitePosts, setWhitePosts] = useState();
    const [categoryPosts, setCategoryPosts] = useState();

    useEffect(() => {
        axios
            .get(`/wp/v2/posts?per_page=4`)
            .then((res) => {
                setLatestPosts(res.data);
            })
            .catch(function () {});

        axios
            .get(`/wp/v2/posts?tags=${config.featuredTagId}`)
            .then((res) => {
                setFeaturedPosts(res.data);
            })
            .catch(function () {});

        axios
            .post(`/api/random`)
            .then((res) => {
                setWhitePosts(res.data);
            })
            .catch(function () {});

        axios
            .post(`/api/random`)
            .then((res) => {
                setCategoryPosts(res.data);
            })
            .catch(function () {});
    }, []);

    return (
        <Box>
            <SecondaryNav />
            <Section title="Latest Blog Posts">
                <PostCardCycle posts={latestPosts} />
                <Link href="/all" underline="none">
                    <Button
                        sx={{
                            display: 'flex',
                            margin: 'auto',
                            marginTop: { xs: 5, md: 8 },
                        }}
                        variant="dark"
                    >
                        View All
                    </Button>
                </Link>
            </Section>
            <Section title="Featured Articles" backgroundColor="tint">
                <PostList posts={featuredPosts} />
            </Section>
            <Section
                title="Other Category"
                description="These are 3 random blog posts. This can be later changed to feature the 3 most recent posts for a specific category. The category description can also be written in place of this paragraph."
            >
                <PostList posts={categoryPosts} attribute="post_name" />
            </Section>
            <Section
                title="White Papers"
                description="Understand how Upside is leveraging new technologies and
                    committed to make the buying and selling process fairer to
                    both sides, making the process faster, less expensive, and
                    more efficient at the same time."
                backgroundColor="tint"
            >
                <PostList posts={whitePosts} attribute="post_name" />
            </Section>
            <Footer />
        </Box>
    );
}

function Section({ title, description, backgroundColor, children }) {
    return (
        <Box
            backgroundColor={backgroundColor}
            paddingTop={{ xs: 7, md: 10 }}
            paddingBottom={{ xs: 7, md: 10 }}
        >
            <PaddedBox>
                <Typography
                    variant="h5"
                    fontSize={22}
                    fontWeight="medium"
                    textTransform="uppercase"
                    color="#919191"
                    marginBottom={{ xs: 2, md: 3 }}
                >
                    {title}
                </Typography>
                <Typography fontSize={18} marginBottom={{ xs: 4, md: 5 }}>
                    {description}
                </Typography>
                {children}
            </PaddedBox>
        </Box>
    );
}

export default Home;
